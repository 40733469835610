import styled from 'styled-components'

export const Title = styled.span`
  color: var(--base-black);
  font-size: var(--font-display-md);
  font-weight: var(--font-weight-medium);
`

export const SubTitle = styled.span`
  color: var(--gray-light-mode-500);
  font-size: var(--font-text-xl);
  font-weight: var(--font-weight-normal);
`

export const FinalPriceValue = styled.p`
  color: var(--gray-light-mode-900);
  font-size: var(--font-display-2lg);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: -0.96px;
  line-height: 1.25;
`

export const ProductPrice = styled.p`
  color: var(--gray-light-mode-900);
  font-size: var(--font-text-lg);
  font-weight: var(--font-weight-semi-bold);
  line-height: 1.56;
`

export const IncentivesPrice = styled(ProductPrice)`
  font-size: var(--font-display-2xs);
`
