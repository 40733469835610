import styled from 'styled-components'
import { Container, Option, OptionsWrapper } from './CommonCalculator'
import { SubTitle, Title } from '@/uiComponents/CommonComponents'
import Select from './Select'
import InputNumber from './InputNumber'
import ToggleOption from './ToggleOption'
import { useCallback, useEffect, useState } from 'react'
import { Ava } from './Calculator'

const OptionTitle = styled.span`
  color: var(--base-black);
  font-size: var(--font-display-xs);
  font-weight: var(--font-weight-semi-bold);
  line-height: 1.33;
  text-align: left;
`

const AdjustSectionTitle = styled(Title)`
  margin-bottom: 45px;
`

type AdjustSectionProps = {
  ava: Ava
  hourlyRate?: number
  setHourlyRate: (hourlyRate?: number) => void
}

enum GPU {
  '24G' = 'Nvidia 4090 24G',
  ADA = 'Nvidia RTX 5000 ADA',
  ADA_X2 = '2 x Nvidia RTX 5000 ADA',
}

const AdjustSection = ({ ava, hourlyRate, setHourlyRate }: AdjustSectionProps) => {
  const [gpu, setGpu] = useState<GPU>(GPU['24G'])
  const [numberOfCards, setNumberOfCards] = useState<number>(1)
  const [ratePerKW, setRatePerKW] = useState<number | undefined>(0.4)
  const [kWs, setKWs] = useState<number | undefined>(100)

  useEffect(() => {
    if (ava === 'light') {
      setGpu(GPU['24G'])
      setNumberOfCards(1)
      setHourlyRate(0.45)
    }
    if (ava === 'pro') {
      setGpu(GPU.ADA)
      setNumberOfCards(1)
      setHourlyRate(1)
    }
    if (ava === 'max') {
      setGpu(GPU.ADA_X2)
      setNumberOfCards(2)
      setHourlyRate(1)
    }
  }, [ava, setHourlyRate])

  const onHandleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, setValue: (value: number | undefined) => void) => {
      const value = event.target.value === '' ? undefined : Number(event.target.value)
      if (value === undefined || value >= 0) {
        setValue(value)
      }
    },
    []
  )

  return (
    <Container>
      <AdjustSectionTitle>Adjust</AdjustSectionTitle>
      <OptionTitle>Compute</OptionTitle>
      <OptionsWrapper>
        <Select title="GPU" width="364">
          <Option value="gpu">{gpu}</Option>
        </Select>
        <Container width="186">
          <SubTitle>Number of cards</SubTitle>
          <InputNumber value={numberOfCards} disabled />
        </Container>
        <Container width="264">
          <SubTitle>Monthly load</SubTitle>
          <InputNumber value={30} symbol="%" symbolPosition={41} disabled />
        </Container>
        <Container width="264">
          <SubTitle>Hourly rate</SubTitle>
          <InputNumber disabled value={hourlyRate} symbol="$" symbolPosition={7} />
        </Container>
      </OptionsWrapper>
      <OptionTitle>Blockchain</OptionTitle>
      <OptionsWrapper>
        <ToggleOption text="Mining" title="Flux" onChange={() => {}} />
        <ToggleOption text="Staking" title="Network name" onChange={() => {}} />
        <ToggleOption text="Mining" title="Network name" onChange={() => {}} />
      </OptionsWrapper>
      <OptionTitle>Grid</OptionTitle>
      <OptionsWrapper>
        <Container width="264">
          <SubTitle>Rate per kW</SubTitle>
          <InputNumber
            value={ratePerKW}
            symbol="$"
            onChange={(event) => {
              onHandleInputChange(event, setRatePerKW)
            }}
            symbolPosition={7}
          />
        </Container>
        <Container width="264">
          <SubTitle># of kW&apos;s</SubTitle>
          <InputNumber
            value={kWs}
            onChange={(event) => {
              onHandleInputChange(event, setKWs)
            }}
          />
        </Container>
      </OptionsWrapper>
    </Container>
  )
}

export default AdjustSection
