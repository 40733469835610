import styled from 'styled-components'

export const Container = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`
export const Option = styled.option`
  font-size: var(--font-text-lg);
  font-weight: var(--font-weight-medium);
  line-height: 1.56;
  text-align: left;
  color: var(--gray-modern-900);
`

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 32px;
  border-radius: var(--radius-4xl);
  margin-bottom: 50px;
  background-color: var(--gray-light-mode-25);
  gap: 32px;
`
