import styled from 'styled-components'
import { Container } from './CommonCalculator'
import { useState } from 'react'
import ToggleSwitch from '@/uiComponents/ToggleSwitch'
import { SubTitle } from '@/uiComponents/CommonComponents'

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 16px;
  border: solid 1px var(--gray-modern-300);
  border-radius: var(--radius-xl);
  margin: 8px 0 0;
  background-color: var(--base-white);
  gap: 16px;
`
const Text = styled.span`
  color: var(--gray-modern-900);
  font-size: var(--font-text-lg);
  font-weight: var(--font-weight-medium);
  line-height: 1.56;
  text-align: left;
`

type ToggleOptionProps = {
  title: string
  text: string
  onChange: (value: boolean) => void
}

const ToggleOption = ({ text, title, onChange }: ToggleOptionProps) => {
  const [toggle, setToggle] = useState<boolean>(true)
  const handleToggle = () => {
    setToggle(!toggle)
    onChange(!toggle)
  }
  return (
    <Container>
      <SubTitle>{title}</SubTitle>
      <Box>
        <ToggleSwitch isChecked={toggle} onChange={handleToggle} />
        <Text>{text}</Text>
      </Box>
    </Container>
  )
}

export default ToggleOption
