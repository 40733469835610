import styled from 'styled-components'
import { TertiaryTitle } from './installationRequest/CommonForm'

const Container = styled.div`
  display: flex;
  width: 669px;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 30px;
  margin-top: 0;
`

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 56px;
  margin-bottom: 24px;
`

export default function SuccessfulSubmissionMessage() {
  return (
    <Container>
      <TertiaryTitle>Greenlight Your Business</TertiaryTitle>
      <TertiaryTitle>
        We do not support your zip code yet. We will contact you as our services become available in the area.
      </TertiaryTitle>
      <Detail>
        <TertiaryTitle>Best,</TertiaryTitle>
        <TertiaryTitle>Karl Andersen</TertiaryTitle>
      </Detail>
      <TertiaryTitle>CEO</TertiaryTitle>
    </Container>
  )
}
