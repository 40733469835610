import { useContext, useEffect } from 'react'
import { PostLoginContext } from './AuthProvider'
import { useNavigate } from 'react-router-dom'

export default function PostLoginPage() {
  const navigate = useNavigate()
  const context = useContext(PostLoginContext)
  const returnToPath = context.appState?.returnToPath as string | undefined
  useEffect(() => {
    if (returnToPath === undefined) {
      return
    }
    navigate(returnToPath)
  }, [returnToPath, navigate])
  return <></>
}
