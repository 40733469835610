import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Logo from '@/assets/icons/logo.svg?react'
import { layoutContainers } from '@/assets/style/mixins.ts'
import { PathConstants } from '@/pathConstant'
import { MobileMenu } from '@/layout/MobileMenu'
import { DesktopMenu } from '@/layout/DesktopMenu'
import { useMediaQuery } from 'react-responsive'

const HeaderEl = styled.header`
  position: fixed;
  z-index: 2;
  top: 0;
  display: flex;
  width: 100%;
  height: var(--header-height);
  background-color: var(--primary-background-color);
  font-weight: var(--font-weight-semi-bold);
  white-space: nowrap;
`

const Wrapper = styled.div`
  ${layoutContainers.largeContainer};
  display: flex;
  align-items: center;
  gap: 40px;
`

const HomeLink = styled(Link)`
  width: fit-content;
`

const StyledLogo = styled(Logo)`
  min-width: 40px;
`
const NAV_LINKS = [
  {
    label: 'For business',
    path: PathConstants.BUSINESS_COLLECT,
  },
  {
    label: 'For home',
    path: PathConstants.RESIDENTIAL,
  },
  {
    label: 'Contact',
    path: PathConstants.CONTACT,
  },
]

export function Header() {
  const isMobileWidth = useMediaQuery({ query: '(max-width: 768px)' })
  return (
    <HeaderEl>
      <Wrapper>
        <HomeLink to={PathConstants.RESIDENTIAL} aria-label="To Assistant" title="To Assistant">
          <StyledLogo width="100" />
        </HomeLink>
        {isMobileWidth ? <MobileMenu navLinksData={NAV_LINKS} /> : <DesktopMenu navLinksData={NAV_LINKS} />}
      </Wrapper>
    </HeaderEl>
  )
}
