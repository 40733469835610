import React, { ChangeEvent } from 'react'

import styled from 'styled-components'

export interface CheckboxProps {
  label: string
  onChange: (checked: boolean) => void
}

export const CheckboxGroup = styled.div`
  display: grid;
  max-width: 596px;
  margin-bottom: 56px;
  gap: 28px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
const CheckboxLabelContainer = styled.label`
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 24px;
  border-radius: 16px;
  border-bottom: 1px solid transparent;
  margin-bottom: 0;
  background-color: var(--secondary-background-color);
  color: var(--secondary-text-color);
  cursor: pointer;
  font-size: var(--font-text-xl);
  font-weight: var(--font-weight-normal);
  gap: 36px;
  line-height: 2rem;

  &:hover {
    border-bottom: 1px solid var(--primary-border-color);
  }
`

const CheckMark = styled.span`
  position: absolute;
  bottom: -18px;
  left: 0;
  width: 32px;
  height: 32px;
  border: solid 1px var(--accent-1-300);
  border-radius: var(--radius-md);
  background-color: var(--base-white);

  &::after {
    position: absolute;
    display: none;
    content: '';
  }
`

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;

  &:checked + ${CheckMark} {
    background-color: var(--accent-1-300);

    &::after {
      top: 7px;
      left: 11px;
      display: block;
      width: 7px;
      height: 14px;
      border: solid var(--base-black);
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
`

const CheckboxLabel = styled.label<{ $disabled?: boolean }>`
  position: relative;
  display: inline-block;
  padding-left: 35px;
  cursor: ${({ $disabled }) => ($disabled ? 'no-drop' : 'pointer')};
`

export const CheckboxComponent: React.FC<CheckboxProps> = ({ label, onChange }) => {
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)
  }

  return (
    <CheckboxLabelContainer htmlFor={label}>
      {label}
      <CustomCheckboxComponent label={label} onChange={handleCheckboxChange} />
    </CheckboxLabelContainer>
  )
}

type CustomCheckboxComponentProps = {
  label?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  checked?: boolean
  disable?: boolean
}

export const CustomCheckboxComponent = ({ label, onChange, checked, disable }: CustomCheckboxComponentProps) => {
  return (
    <CheckboxLabel $disabled={disable}>
      <Checkbox disabled={disable} checked={checked} id={label} name={label} onChange={onChange} />
      <CheckMark />
    </CheckboxLabel>
  )
}
