import { IncentivesPrice, SubTitle, Title } from '@/uiComponents/CommonComponents'
import { Container, Option } from './CommonCalculator'
import Select from './Select'
import styled from 'styled-components'
import { useState } from 'react'
import { usdFormatter } from '@/salesAssistant/packagePreview/usdFormatter'

const EstimatedSystemTitle = styled(Title)`
  margin-bottom: 40px;
`

const SystemOptionContainer = styled.div`
  display: flex;
  width: 164px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 40px 24px;
  border-radius: var(--radius-2xl);
  background-color: var(--gray-modern-50);
  gap: 8px;
`

const SystemOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 0;
  gap: 24px;
`

type EstimatedSystemProps = {
  hourlyRate?: number
}

const EstimatedSystem = ({ hourlyRate }: EstimatedSystemProps) => {
  const [period, setPeriod] = useState<'monthly' | 'yearly'>('monthly')

  const days = period === 'monthly' ? 31 : 365

  const computeProfits = days * 24 * (30 / 100) * (hourlyRate ?? 0)
  const storageProfits = days * 24 * (2000 - 200) * 0.000003

  return (
    <Container>
      <EstimatedSystemTitle>Estimated system cost & profits</EstimatedSystemTitle>
      <Select
        width="264"
        onChange={(value) => {
          setPeriod(value as 'monthly' | 'yearly')
        }}
        value={period}
      >
        <Option value="monthly">Monthly</Option>
        <Option value="yearly">Yearly</Option>
      </Select>
      <SystemOptionsWrapper>
        <SystemOptionContainer>
          <SubTitle>Compute</SubTitle>
          <IncentivesPrice>{usdFormatter.format(computeProfits)}</IncentivesPrice>
        </SystemOptionContainer>
        <SystemOptionContainer>
          <SubTitle>Blockchain</SubTitle>
          <IncentivesPrice>$22.17</IncentivesPrice>
        </SystemOptionContainer>
        <SystemOptionContainer>
          <SubTitle>Storage</SubTitle>
          <IncentivesPrice>{usdFormatter.format(storageProfits)}</IncentivesPrice>
        </SystemOptionContainer>
        <SystemOptionContainer>
          <SubTitle>Grid</SubTitle>
          <IncentivesPrice>$1.26</IncentivesPrice>
        </SystemOptionContainer>
      </SystemOptionsWrapper>
    </Container>
  )
}

export default EstimatedSystem
