import { css } from 'styled-components'
export const layoutContainers = {
  largeContainer: () => css`
    width: 100%;
    max-width: 1280px;

    @media (max-width: 768px) {
      padding-inline: 16px 12px;
    }
  `,
}
