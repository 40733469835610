import { createContext, useState, ReactNode, useMemo } from 'react'

export type OptimizedPackageContextType = {
  packageLoading: boolean
  setPackageLoading: React.Dispatch<React.SetStateAction<boolean>>
  setLearnMoreQuestions: React.Dispatch<React.SetStateAction<string[] | undefined>>
  learnMoreQuestions?: string[]
}

export const OptimizedPackagedContext = createContext<OptimizedPackageContextType>({
  packageLoading: true,
  setPackageLoading: () => {},
  setLearnMoreQuestions: () => {},
})

export const OptimizedPackageProvider = ({ children }: { children: ReactNode }) => {
  const [packageLoading, setPackageLoading] = useState(false)
  const [learnMoreQuestions, setLearnMoreQuestions] = useState<string[] | undefined>()

  const providerObject = useMemo(
    () => ({
      packageLoading,
      setPackageLoading,
      learnMoreQuestions,
      setLearnMoreQuestions,
    }),
    [learnMoreQuestions, packageLoading]
  )
  return <OptimizedPackagedContext.Provider value={providerObject}>{children}</OptimizedPackagedContext.Provider>
}
