import { useEffect, useState } from 'react'
import styled from 'styled-components'
import CalculatorCard from './CalculatorCard'
import AdjustSection from './AdjustSection'
import EstimatedSystem from './EstimatedSystem'

const CalculatorSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 43px;
`

export type Ava = 'light' | 'pro' | 'max'

const Calculator = () => {
  const [ava, setAva] = useState<Ava>('light')
  const [hourlyRate, setHourlyRate] = useState<number | undefined>()

  useEffect(() => {
    document.title = 'Calculator'
  }, [])

  return (
    <CalculatorSection>
      <CalculatorCard ava={ava} onAvaChange={setAva} />
      <AdjustSection ava={ava} hourlyRate={hourlyRate} setHourlyRate={setHourlyRate} />
      <EstimatedSystem hourlyRate={hourlyRate} />
    </CalculatorSection>
  )
}

export default Calculator
