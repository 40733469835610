import { initializeApp } from 'firebase/app'
import { Analytics, getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyCgYE4x85rm6Ezcck3-sEDeSGt6YJHYASI',
  authDomain: 'studied-theater-402100.firebaseapp.com',
  projectId: 'studied-theater-402100',
  storageBucket: 'studied-theater-402100.appspot.com',
  messagingSenderId: '882658257491',
  appId: '1:882658257491:web:e8740fd248a7c50eeff4a6',
  measurementId: 'G-12G4SK6T8C',
}

let analytics: Analytics | undefined

export function initFirebaseAnalytics(): Analytics {
  if (!analytics) {
    analytics = getAnalytics(initializeApp(firebaseConfig))
  }
  return analytics
}
