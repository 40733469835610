import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { routes } from './routes.tsx'
import '@/assets/style/global.css'
import { initFirebaseAnalytics } from './analytics.ts'
import AuthProvider from './auth/AuthProvider.tsx'
import * as Sentry from '@sentry/react'
import { StyleSheetManager } from 'styled-components'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN as string,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

if (import.meta.env.VITE_ENABLE_ANALYTICS === 'true') {
  initFirebaseAnalytics()
}

const rootElement = document.getElementById('root')

if (rootElement) {
  const router = createBrowserRouter(routes())

  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <StyleSheetManager enableVendorPrefixes={true}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </StyleSheetManager>
    </React.StrictMode>
  )
} else {
  console.error("Element with id 'root' not found.")
}
