import { RouteObject, Navigate } from 'react-router-dom'
import { Layout } from './layout/Layout.tsx'
import { SalesAssistantLayout } from './salesAssistant/SalesAssistantLayout.tsx'
import { PathConstants } from './pathConstant.ts'
import BusinessCollectPage from './installationRequest/BusinessCollectPage.tsx'
import { lazy } from 'react'
import PostLoginPage from './auth/PostLoginPage.tsx'
import PostLogoutPage from './auth/PostLogoutPage.tsx'
import Ratings from './Ratings.tsx'
import Calculator from './computeCalculator/Calculator.tsx'

export function routes(): RouteObject[] {
  const ForecastPageLazy = lazy(() => import('./salesAssistant/packagePreview/forecast/ForecastPage'))
  const SalesAssistantLazy = lazy(() => import('./salesAssistant/SalesAssistant'))

  return [
    {
      path: PathConstants.HOME,
      element: <Navigate to={PathConstants.RESIDENTIAL} replace />,
    },
    {
      element: <Layout />,
      children: [
        {
          path: PathConstants.POST_LOGIN,
          element: <PostLoginPage />,
        },
        {
          path: PathConstants.POST_LOGOUT,
          element: <PostLogoutPage />,
        },
        {
          path: PathConstants.BUSINESS_COLLECT,
          element: <BusinessCollectPage />,
        },
        {
          path: PathConstants.FORECAST,
          element: <ForecastPageLazy />,
        },
        {
          path: PathConstants.RATINGS,
          element: <Ratings />,
        },
        {
          path: PathConstants.CALCULATOR,
          element: <Calculator />,
        },
      ],
    },
    {
      element: <SalesAssistantLayout />,
      children: [
        {
          path: PathConstants.RESIDENTIAL,
          element: <SalesAssistantLazy />,
        },
      ],
    },
  ]
}
