export const PathConstants = {
  HOME: '/',
  RESIDENCE_COLLECT: 'residence-collect',
  BUSINESS_COLLECT: 'business-collect',
  RESIDENTIAL: 'residential',
  CONTACT: 'https://ava.energy/',
  CALCULATOR: 'calculator',
  FORECAST: 'forecast',
  RATINGS: 'ratings',
  POST_LOGIN: 'post-login',
  POST_LOGOUT: 'post-logout',
}
