import { createContext, useState, ReactNode, useMemo } from 'react'

export type InstallationRequestContextType = {
  proceedWithInstallationRequest: boolean
  setProceedWithInstallationRequest: React.Dispatch<React.SetStateAction<boolean>>
}

export const InstallationRequestContext = createContext<InstallationRequestContextType>({
  proceedWithInstallationRequest: false,
  setProceedWithInstallationRequest: () => {},
})

export const InstallationRequestProvider = ({ children }: { children: ReactNode }) => {
  const [proceedWithInstallationRequest, setProceedWithInstallationRequest] = useState<boolean>(false)

  const providerObject = useMemo(
    () => ({
      proceedWithInstallationRequest,
      setProceedWithInstallationRequest,
    }),
    [proceedWithInstallationRequest]
  )
  return <InstallationRequestContext.Provider value={providerObject}>{children}</InstallationRequestContext.Provider>
}
