import styled from 'styled-components'
import avaImg from '@/assets/images/calculator/ava.webp'
import avaImgx2 from '@/assets/images/calculator/ava@2x.webp'
import avaImgx3 from '@/assets/images/calculator/ava@3x.webp'
import Select from './Select'
import { Container, Option } from './CommonCalculator'
import { usdFormatter } from '@/salesAssistant/packagePreview/usdFormatter'
import { FinalPriceValue } from '@/uiComponents/CommonComponents'
import { Ava } from './Calculator'

const CardContainer = styled.article`
  display: flex;
  width: 752px;
  height: 474px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 24px;
  border: solid 4px var(--gray-modern-100);
  border-radius: 32px;
`
const ImageContainer = styled.div`
  width: 256px;
  height: 260px;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: var(--radius-4xl);
  object-fit: cover;
`

const SeparatorFrame = styled.span`
  height: 4px;
  flex-grow: 0;
  align-self: stretch;
  border-radius: var(--radius-full);
  background-color: var(--gray-modern-100);
`
const AttributesContainer = styled.div`
  display: grid;
  gap: 20px 40px;
  grid-template-columns: repeat(2, 1fr);
`

const AttributeLabel = styled.span`
  color: var(--gray-modern-900);
  font-size: var(--font-text-md);
  font-weight: var(--font-weight-bold);
  text-align: left;
`

const AttributeName = styled.p`
  color: var(--gray-modern-900);
  font-size: var(--font-text-md);
  font-weight: var(--font-weight-medium);
  text-align: left;
`

const Wrapper = styled.div`
  display: flex;
  margin-top: 40px;
  gap: 50px;
`

const CardInfoContainer = styled(Container)`
  gap: 32px;
`

type CalculatorCardProps = {
  ava: Ava
  onAvaChange: (ava: Ava) => void
}

const CalculatorCard = ({ ava, onAvaChange }: CalculatorCardProps) => {
  const imageSrcSet = `${avaImg} 1x, ${avaImgx2} 2x, ${avaImgx3} 3x`

  const getPrice = () => {
    switch (ava) {
      case 'light':
        return 4450
      case 'pro':
        return 9450
      case 'max':
        return 16450
      default:
        return 0
    }
  }

  return (
    <CardContainer>
      <Select
        title="Ava"
        onChange={(e) => {
          onAvaChange(e as Ava)
        }}
      >
        <Option value="light">Light</Option>
        <Option value="pro">Pro</Option>
        <Option value="max">Max</Option>
      </Select>
      <Wrapper>
        <ImageContainer>
          <Image src={avaImg} srcSet={imageSrcSet} alt="" />
        </ImageContainer>
        <CardInfoContainer>
          <AttributesContainer>
            <Container>
              <AttributeLabel>CPU</AttributeLabel>
              <AttributeName>CPU name goes here</AttributeName>
            </Container>
            <Container>
              <AttributeLabel>RAM</AttributeLabel>
              <AttributeName>16 Gb</AttributeName>
            </Container>
            <Container>
              <AttributeLabel>SSD Hard Drive</AttributeLabel>
              <AttributeName>2Tb</AttributeName>
            </Container>
            <Container>
              <AttributeLabel>Motherboard</AttributeLabel>
              <AttributeName>Gigabyte</AttributeName>
            </Container>
          </AttributesContainer>
          <SeparatorFrame />
          <Container>
            <AttributeLabel>System cost</AttributeLabel>
            <FinalPriceValue>{usdFormatter.format(getPrice())}</FinalPriceValue>
          </Container>
        </CardInfoContainer>
      </Wrapper>
    </CardContainer>
  )
}

export default CalculatorCard
