import { useCallback, useEffect, useState } from 'react'
import SuccessfulSubmissionMessage from '@/SuccessfulSubmissionMessage'
import { CheckboxComponent, CheckboxGroup } from '@/uiComponents/Checkbox'
import { FormInput } from '@/uiComponents/FormInput'
import { FormContainer, FormHeadingTitle, FormSecondaryTitle, TertiaryTitle } from '@/installationRequest/CommonForm'
import { prepareBusinessInstallationRequest } from '@/installationRequest/prepareInstallationRequest.ts'
import { ValidationError, businessSchema } from '@/installationRequest/validationSchema'
import { HeroButton } from '@/uiComponents/button/Button'
import { useProfile } from '@/profile/useProfle'
import { requestInstallation } from './installationRequestsApi'

export default function BusinessCollectPage() {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [errors, setErrors] = useState<ValidationError>({})
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [isFormEdited, setIsFormEdited] = useState(false)
  const { profile, refreshProfile } = useProfile()

  const [formData, setFormData] = useState({
    solar: false,
    wind: false,
    battery: false,
    fullName: '',
    title: '',
    email: '',
    phone: '',
    businessName: '',
    businessAddress: '',
    industry: '',
  })

  useEffect(() => {
    document.title = 'Ava Business'
  }, [])

  useEffect(() => {
    if (!isFormEdited) {
      void refreshProfile().then(() => {
        setIsDataLoaded(true)
      })
    }
  }, [refreshProfile, isFormEdited])

  useEffect(() => {
    if (isDataLoaded && !isFormEdited) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        fullName: profile?.name ?? '',
        businessAddress: profile?.address ?? '',
        email: profile?.email ?? '',
        phone: profile?.phone ?? '',
      }))
    }
  }, [isDataLoaded, isFormEdited, profile?.address, profile?.email, profile?.name, profile?.phone])

  const handleCheckboxChange = useCallback(
    (label: string) => (checked: boolean) => {
      setFormData((prevFormData) => ({ ...prevFormData, [label]: checked }))
    },
    []
  )

  const handleInputChange = useCallback(
    (label: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      setFormData((prevFormData) => ({ ...prevFormData, [label]: value }))
      setIsFormEdited(true)
    },
    []
  )

  const sendFormData = async () => {
    try {
      await requestInstallation(prepareBusinessInstallationRequest(formData))
      setIsSubmitted(true)
    } catch (apiError) {
      console.log(apiError)
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { error } = businessSchema.validate(formData, { abortEarly: false })
    if (error) {
      const validationErrors: ValidationError = {}
      error.details.forEach((detail) => {
        validationErrors[detail.path[0]] = detail.message
      })
      setErrors(validationErrors)
    } else {
      setErrors({})
      void sendFormData()
    }
  }

  if (isSubmitted) {
    return <SuccessfulSubmissionMessage />
  }

  return (
    <FormContainer onSubmit={handleSubmit} noValidate>
      <TertiaryTitle>Greenlight Your Business</TertiaryTitle>
      <FormHeadingTitle>Get a Quote</FormHeadingTitle>
      <FormSecondaryTitle>What products are you interested in?</FormSecondaryTitle>
      <CheckboxGroup>
        <CheckboxComponent label="Solar" onChange={handleCheckboxChange('solar')} />
        <CheckboxComponent label="Wind" onChange={handleCheckboxChange('wind')} />
        <CheckboxComponent label="Battery" onChange={handleCheckboxChange('battery')} />
      </CheckboxGroup>
      <FormSecondaryTitle>Tell us 👋 more about yourself and your business!</FormSecondaryTitle>
      <FormInput
        name="fullName"
        label="Full Name"
        required={true}
        type="text"
        onChange={handleInputChange('fullName')}
        value={formData.fullName}
        errorMessage={errors['fullName']}
      />
      <FormInput name="title" label="Title" type="text" onChange={handleInputChange('title')} value={formData.title} />
      <FormInput
        name="email"
        label="Email"
        required={true}
        type="text"
        onChange={handleInputChange('email')}
        value={formData.email}
        errorMessage={errors['email']}
      />
      <FormInput
        name="phone"
        label="Phone"
        required={true}
        type="text"
        onChange={handleInputChange('phone')}
        value={formData.phone}
        errorMessage={errors['phone']}
      />
      <FormInput
        name="businessName"
        label="Business Name"
        required={true}
        type="text"
        onChange={handleInputChange('businessName')}
        value={formData.businessName}
        errorMessage={errors['businessName']}
      />
      <FormInput
        name="businessAddress"
        label="Business Address"
        required={true}
        type="text"
        onChange={handleInputChange('businessAddress')}
        value={formData.businessAddress}
        errorMessage={errors['businessAddress']}
      />
      <FormInput
        name="industry"
        label="Industry"
        type="text"
        onChange={handleInputChange('industry')}
        value={formData.industry}
      />
      <div>
        <HeroButton text="Submit" size="2xl" type="submit" />
      </div>
    </FormContainer>
  )
}
